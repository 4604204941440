import React, { Component } from 'react';
import { Select} from 'antd';
import {connect} from 'react-redux'
import { imgProxyResize } from '../utils'
import ReactDOM from "react-dom";


const { Option } = Select;

 
function extractName(path){
  const split = path.split("/");
  return split[split.length-1]
}
  
class FileInput extends React.Component {
    
    onChange = (val) => {
      this.props.onChange(val)
    }

    componentDidMount(){
      try{
          const node = ReactDOM.findDOMNode(this);
        if(node){
          const selector = node.getElementsByClassName('ant-select-selector')[0]
          if(selector){
            selector.style.height = '80px'
          }
        }
      }catch(err){
        console.log(err)
      }
      
    }
    
    render() {
      return (
      <div> 
        <Select allowClear = {true} placeholder = 'None' value={this.props.value} style={{ width: '100%'}}  onChange={this.onChange}>
            {this.props.assets.map(asset => 
              <Option key = {asset.id} value={asset.file} >
                <img
                  style = {{height:'72px', width:'auto', maxWidth: '128px', paddingTop:'6px', paddingRight:'12px'}}
                  src={ imgProxyResize(asset.file, 'fit', 128, 72) }
                />
                {extractName(asset.file)}
              </Option>
              )}
        </Select>
      </div>
      );
    }
  }


export default connect(state => {
  return {assets:state.assets};
})(FileInput);

