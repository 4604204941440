import React, { Component } from 'react';
import { Form, Input, Button, Checkbox, message } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import * as actions from '../store/actions/session'


class Login extends Component {
    
    onLogin = ({username, password, remember}) => {
        this.props.dispatch(actions.authLogin(username, password, remember))
    }

    render() {
        return (
            <div style = {{backgroundColor:'white', width:'372px', padding:'36px', margin:'auto', marginTop:'30px', boxShadow: '0px 0px 6px #AAA'}}>
            <Form
                name="normal_login"
                style={{maxWidth:'300px'}}
                initialValues={{
                    remember: false,
                }}
                onFinish={this.onLogin}
                >
                <Form.Item
                    name="username"
                    rules={[
                    {
                        required: true,
                        message: 'Please input your Username!',
                    },
                    ]}
                >
                    <Input disabled = {this.props.session.loading} prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
                </Form.Item>
                <Form.Item
                    name="password"
                    rules={[
                    {
                        required: true,
                        message: 'Please input your Password!',
                    },
                    ]}
                >
                    <Input
                    disabled = {this.props.session.loading}
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="Password"
                    />
                </Form.Item>
                
                <Form.Item name="remember" valuePropName="checked" noStyle>
                    <Checkbox
                    disabled = {this.props.session.loading}
                    >Remember me</Checkbox>
                </Form.Item>
                

                <Form.Item>
                    <Button type="primary" htmlType="submit" style = {{width:'100%', top:'20px'}} loading = {this.props.session.loading}>
                    Log in
                    </Button>
                </Form.Item>

            </Form>
            </div>
        );

    }
}

export default Login;
