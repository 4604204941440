import React, { Component } from 'react';
import Login from './Login';
import Editor from './Editor';
import {connect} from 'react-redux'
import * as actions from '../store/actions/session'
import { BrowserRouter as Router, Route } from 'react-router-dom';




class App extends Component {

  componentDidMount(){
    this.props.dispatch(actions.authCheckState());
  }

  render(){
    return <div>
      <Router>
        <Route path = "/" render = {(match) => (this.props.session.isLoggedIn ? <Editor route = {match}/> : <Login { ...this.props } />)}/>    
      </Router>
    </div>
  }
}

export default connect(state => {
  return {session:state.session };
})(App);
