import Imgproxy from 'imgproxy'


/**
 * A shortcut function that returns preconfigured ImgProxy builder
 */
export function imgProxy () {
  const im = new Imgproxy({
    baseUrl: process.env.REACT_APP_IMGPROXY_URL || 'https://imgproxy-sedna-cms.advent.zone',
    encode: false
  })
  return im.builder()
}

/**
 * A shortcut that returns thumbnail URL for given srcUrl and
 * @param {*} srcUrl URL of the original image
 * @param {*} args args to resize function as per https://docs.imgproxy.net/#/generating_the_url_advanced?id=resize
 */
export function imgProxyResize (srcUrl, resizeType, width, height) {
  return imgProxy()
    .resize(resizeType, width, height)
    .generateUrl(srcUrl)
}
