import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utils';
import _ from 'lodash'


const initialState = {
    apps : []
}

const setApps = (state, action) => {
    return action.apps
}

const addApp = (state, action) => {
    const {app, groupID} = action;
    const appGroups = _.cloneDeep(state);
    const group = appGroups.find(group => (group.id === groupID))
    group.apps.push(app);
    return appGroups;
}

const updateApp = (state, action) => {
    const {appID, data} = action;
    const appGroups = _.cloneDeep(state);
    const group = appGroups.find(group => (group.apps.find(app => (app.id === appID))))
    const appIndex = group.apps.findIndex(app => (app.id === appID))
    group.apps[appIndex] = data
    return appGroups;
}

const updateAppsList = (state, action) => {
    const {screenID, apps_list} = action;
    const apps = _.cloneDeep(state);
    let screen;
    apps.map(group => (group.subgroups.map(subgroup => (subgroup.screens.map(currScreen => {
        if(currScreen.id === screenID){ 
            screen = currScreen
        }
    })))));
    console.log(screen)
    screen.apps_list = apps_list;
    return apps;
}

const deleteApp = (state, action) => {
    const {appID} = action;
    const appGroups = _.cloneDeep(state);
    const group = appGroups.find(group => (group.apps.find(app => (app.id === appID))))
    const appIndex = group.apps.findIndex(app => (app.id === appID))
    group.apps.splice(appIndex, 1)
    return appGroups;
}

const appsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_APPS: return setApps(state, action);
        case actionTypes.ADD_APP: return addApp(state, action);
        case actionTypes.UPDATE_APP: return updateApp(state, action);
        case actionTypes.DELETE_APP: return deleteApp(state, action);
        case actionTypes.UPDATE_APPS_LIST: return updateAppsList(state, action);

        /*
        case 'updateApp':
            const {groupIndex, appIndex, stageApp} = payload
            result = _.cloneDeep(state);
            result[groupIndex].apps[appIndex] = stageApp
            break
            */
        default:
            return state;
    }
}

export default appsReducer;