import React, { Component } from 'react';
import {Button, message, Tabs, DatePicker, Select} from 'antd';
import moment from 'moment'

const { RangePicker } = DatePicker;

class SchedulerInput extends Component {

    constructor (props) {
        super(props)
        console.log(props.app.occurrences)
    }

    trimDate = (dateStr) => {
        return dateStr.substr(0,16);
    }

    onChange = (range) => {
        // delete range
        if(range === null){
            this.props.setOccurences(null)
        }else{
            this.onOk(range)
        }
    }

    onOk = (range) => {
        if(range[0] && range[1]){
            this.props.setOccurences({start:this.trimDate(range[0].format()), end:this.trimDate(range[1].format())})
        }
    }

    getCurrentRange = () => {
        const range = this.props.stagedApp.occurrences[0];
        //console.log('getCurrentRange', this.trimDate(range.start), this.trimDate(range.end))
        if(!range){
           return []
        }
        else{
            return [moment(this.trimDate(range.start)), moment(this.trimDate(range.end))]
        }
        
    }

    render() {
        return (
            <div style = {{ marginBottom:'12px'}}>
            <h3>Set the date/time range in machine <strong>local time</strong></h3>
            <RangePicker
            style = {{width:'100%'}}
            format="MM-DD-YYYY h:mm a"
            showTime={{ format:"h:mm a" }}
            onChange={this.onChange}
            onOk={this.onOk}
            value = {this.getCurrentRange()}
            />
        </div>
        );
    }
}

export default SchedulerInput;