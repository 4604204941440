import React from 'react';
import ReactDOM from 'react-dom';
import App from './containers/App';
import { sampleData } from './data/sampleData'
import { createStore, compose, applyMiddleware, combineReducers} from 'redux';
import { assetsReducer, templatesReducer, screensReducer } from './store/reducers'
import sessionReducer from './store/reducers/session'
import appsReducer from './store/reducers/apps'
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import './index.css';
import 'antd/dist/antd.css';
import * as Sentry from '@sentry/browser'

const composeEnhances = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const reducers = combineReducers({session:sessionReducer, apps:appsReducer, templates:templatesReducer, screens:screensReducer, assets:assetsReducer})
const store = createStore(reducers, sampleData, composeEnhances(applyMiddleware(thunk)));

Sentry.init({dsn:'https://f6ef955a6868483ab3354c50eed279c3@sentry.blueprint.pw/101'})

ReactDOM.render(
    <Provider store = {store}>
        <App />
    </Provider>,
    document.getElementById('root')
);
