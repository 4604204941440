import axios from 'axios';
import * as actionTypes from './actionTypes';
import * as EP from '../../data/paths'
import { message } from 'antd';


export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    }
}

export const authSuccess = (token, username) => {
    axios.defaults.headers.common['Authorization'] = `JWT ${token}`;
    return {
        type: actionTypes.AUTH_SUCCESS,
        token: token,
        username
    }
}

export const authFail = error => {
    message.error(error.message)
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    }
}

export const logout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    return {
        type: actionTypes.AUTH_LOGOUT
    };
}

export const checkAuthTimeout = expirationTime => {
    return dispatch => {
        setTimeout(() => {
            dispatch(logout());
        }, expirationTime * 1000)
    }
}

export const authLogin = (username, password, remember) => {
    return dispatch => {
        dispatch(authStart());
        axios.post(EP.LOGIN(), { username, password })
        .then(res => {
            const token = res.data.token;
            if(remember){
                localStorage.setItem('token', token);
                localStorage.setItem('username', username); 
            }
            dispatch(authSuccess(token, username));
        })
        .catch(error => {
            console.log(error);
            dispatch(authFail(error))
        })
    }
}

export const authCheckState = () => {
    return dispatch => {
        const token = localStorage.getItem('token');
        if (!token) {
            dispatch(logout());
        } else {
            dispatch(authStart())
            dispatch(verifyToken(token, localStorage.getItem('username')));
        }
    }
}

export const verifyToken = (token, username) => {
    return dispatch => {
        axios.post(EP.VERIFY_TOKEN(), { token })
        .then(res => {
            dispatch(authSuccess(token, username));
        })
        .catch(error => {
            console.log(error);
            dispatch(authFail(error))
        })
    }
}