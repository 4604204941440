export const sampleData = {
    session:{
        isLoggedIn:false,
        username:'John Doe',
        token:'abc'
    },
    apps:null,
    templates:null,
    screens:[
      {name:'San Francisco',
       buildings:[
         {name:'Campus',
          screens:[
            {name:'Elevator'},
            {name:'Entrance'},
            {name:'Hall'},
            {name:'Restrooms'},
            {name:'1st floor'}
          ]
         },
         {name:'Office',
          screens:[
            {name:'Entrance'},
            {name:'Registration'},
            {name:'Restrooms'}
          ]
         }
       ]
      },
      {name:'San Jose',
       buildings:[
         {name:'Mall',
          screens:[
            {name:'Cinema area'},
            {name:'1st floor'},
            {name:'2nd floor'},
            {name:'3rd floor'},
            {name:'4th floor'},
          ]
         },
         {name:'Hotel',
          screens:[
            {name:'Entrance'},
            {name:'Bar'},
            {name:'Restaurant'}
          ]
         }
       ]
      }
    ]
};
