/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import React, { Component } from 'react';
import {MenuOutlined, RightOutlined, MinusCircleOutlined} from '@ant-design/icons';
import { imgProxyResize } from '../utils'
import {sortableHandle} from 'react-sortable-hoc';
import '../index.css';


const DragHandle = sortableHandle(() => <MenuOutlined style = {{cursor: 'row-resize', color: '#ddd', paddingTop: '5px', paddingRight:'10px', float:'left', lineHeight:'40px', fontSize: '20px'}}/>);


class ContentObjectItem extends Component {
    
    


    getImage = () => {
        
        const templateID = this.props.app.template;
        const template = this.props.templates.find(template => (template.id === templateID))
        let image;
        for (let fieldName in template.ui_schema){
            if(template.ui_schema[fieldName]['ui:widget'] === 'file' ){
                if(this.props.app.data[fieldName]){
                    image = this.props.app.data[fieldName];
                    break;
                }
            }
        }
        return image;
        //console.log('image', image)
    }

    render() {
        const {app, dragable, clickable} = this.props;
        const style = {
            border:'solid 1px #ddd', 
            padding:'10px',
            display:'inline-block',
            width:'100%',
            backgroundColor: '#fff',
            userSelect: 'none'
        }

        if(clickable || true){
            style.cursor = 'pointer'
            style.transition = 'all 0.3s'
            style[':hover'] = {
                border:'solid 1px #1890ff',
                color:'#1890ff'
            }
        }

        return (
            <div 
                css ={style}>
                {dragable && <DragHandle/>}
                <div style = {{float:'left', width: '80px', height:'45px', backgroundColor: '#ddd'}} id= 'preview'>
                    {this.getImage() && <img src={imgProxyResize(this.getImage(), 'fit', 80, 45)}/>}
                </div>
                <span style = {{pointerEvents: 'none', lineHeight:'45px', paddingLeft:'10px'}}>{`${app.name}`}</span>
                {<RightOutlined style = {{pointerEvents: 'none', paddingTop: '5px', paddingRight:'10px',lineHeight:'40px', float: 'right', fontSize: '20px'}}/>}
            </div>
            
        );
    }
}

export default ContentObjectItem;