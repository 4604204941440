import React from 'react';
import { Input, InputNumber, Select} from 'antd';
import FileInput from './FileInput'
import ListInput from './ListInput';


const { Option } = Select;
const { TextArea } = Input;

const GenericInput = props => {
    const {name, type, title, description, appId, widget} = props.definition;
    const {update, groupProperties} = props
    let disabled = false;
    let value = props.properties[name] 
    //|| props.definition.default;
    if(groupProperties && groupProperties[name] != null){
        value = groupProperties[name];
        disabled = true;
    }


    return (
        <div style = {{ marginBottom:'24px'}}>
            
                <h3>{title}</h3>
            
            {/*
            <span style = {{marginBottom:'8px', fontSize:'11px', color:'#999', width:'100%'}}>
                {description}
            </span>
            */}
            {getInput(type, widget, value, name, update, disabled, appId, description, props.definition)}
        </div>
    );
};

const getInput = (type, widget, value, name, update, disabled, appId, description, definition) =>{

    const onChange = (e) => {
        update(name, e.target.value)
    }

    const onChangeInt = (value) => {
        update(name, value)
    }

    const onChangeFile = (value) => {
        update(name, value)
    }

    const onChangeSelect = (value) => {
        update(name, value)
    }

    const onChangeList = (value) => {
        update(name, value)
    }

    if(widget === 'file')
        return <FileInput key = {value ? value : "empty"} appId = {appId} onChange={(val) => update(name, val)} value={value}/>

    else if(widget === 'textarea')
        return <TextArea value = {value} onChange={onChange} disabled = {disabled} rows={4}/>

    if(type === 'integer')
        return <InputNumber style = {{width:'100%'}} value = {value} onChange={onChangeInt} disabled = {disabled} precision = {0}/>

    else if(type === 'number')
        return <InputNumber style = {{width:'100%'}} value = {value} onChange={onChangeInt} disabled = {disabled} precision = {2}/>

    else if(type === 'boolean')
        return <Select value={value} style={{ width: '100%' }} >
        <Option value="yes">Yes, please!</Option>
        <Option value="no">No, thank you.</Option>
        </Select>
    
    else if(type === 'array'){
        return <ListInput value = {value || []} schema={definition.items.properties} onChange={onChangeList}/>   
    }
         

    if(name === 'mode')
        return <Select value={value} onChange={onChangeSelect} style={{width:'100%'}}>
            {description.split('/').map((e, i) => <Option key = {i} value={e.replace(/ /g, '')}>{e}</Option>)}
        </Select>

    else if(name === 'colorScheme')
        return <Select value={value} onChange={onChangeSelect} style={{width:'100%'}}>
            {description.split("/").map((e, i) => <Option key = {i} value={e.replace(/ /g, '')}>{e}</Option>)}
        </Select>

    return <Input value = {value} onChange={onChange} disabled = {disabled}/>
}

export default GenericInput;