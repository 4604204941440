export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

export const SET_APPS = 'SET_APPS';
export const ADD_APP = 'ADD_APP';
export const UPDATE_APP = 'UPDATE_APP';
export const DELETE_APP = 'DELETE_APP';
export const UPDATE_APPS_LIST = 'UPDATE_APPS_LIST';

export const SET_ASSETS = 'SET_ASSETS';
//export const LOAD_ASSETS = 'SET_ASSETS';
export const REMOVE_ASSET = 'REMOVE_ASSETS';