import React, { Component } from 'react';
import {connect} from 'react-redux'
import * as EP from '../data/paths'
import { Upload, message, Popconfirm, Table, Modal } from 'antd';
import { UploadOutlined} from '@ant-design/icons';
import _ from 'lodash'
import moment from 'moment';
import * as actions from '../store/actions/assets'
import filesize from 'filesize'

import { imgProxyResize } from '../utils'

const { Dragger } = Upload;

function extractName(path){
    const split = path.split("/");
    return shortenName(split[split.length-1])
}

function shortenName(name){
    const nameLength = name.length;
    if(nameLength > 32){
        name = "..." + name.substring(nameLength - 32, nameLength)
    }
    return name;
}

class FileManager extends Component {

    constructor(props){
        super(props)
        //console.log('FileManager', props)
        this.state = {
            loading: false,
            file: null,
            fileList: [],
            modal:null
          };
    }

    columns = [
        {
            title: '',
            dataIndex: 'file',
            render : (path) => <img
                style = {{height:'36px', width:'auto', maxWidth: '64px', margin: 'auto', cursor:'pointer'}}
                src={ imgProxyResize(path, 'fit', 100) }
                onClick = {() => this.showPreview(path)}
                />
        },
        {
            title: 'Name',
            dataIndex: 'file',
            sorter: (a, b) => {
                return(extractName(a.file).localeCompare(extractName(b.file)))

            },
            sortDirections: ['descend', 'ascend'],
            render : (path) => extractName(path)
        },
        {
            title: 'Date',
            dataIndex: 'created',
            sorter: (a, b) => a.created > b.created,
            sortDirections: ['descend', 'ascend'],
            render : date => moment(date).format("MMM Do YYYY, h:mm a")
        },
        {
            title: 'Size',
            dataIndex: 'size',
            sorter: (a, b) => a.size > b.size,
            sortDirections: ['descend', 'ascend'],
            render : size => filesize(size, {round: 0})
        },
        {
            title: 'User',
            dataIndex: 'user',
            sorter: (a, b) => {
                return(extractName(a.user.username).localeCompare(extractName(b.user.username)))
            },
            sortDirections: ['descend', 'ascend'],
            render : user => user.username
        },
        {
            title: '',
            dataIndex: 'id',
            render: (id) =>
            <Popconfirm title="Sure to delete?" onConfirm={() => this.deleteFile(id)}>
                <a>Delete</a>
            </Popconfirm>
        },
      ];

    deleteFile = (id) => {
        this.props.dispatch(actions.deleteAsset(id));
    }

    showPreview = (file) => {
        console.log('showPreview', file)
        this.setState({modal:{file}})
    }

    beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
          message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 <5;
        if (!isLt2M) {
          message.error('Image must smaller than 5MB!');
        }
        return isJpgOrPng && isLt2M;
      }

    getData = () => {
        const data = _.cloneDeep(this.props.assets);

        data.forEach(asset => {
            asset.key = String(asset.id)
            asset.thumbUrl = imgProxyResize(asset.file, 'fit', 100)
        });

        return data;
    }

    onChange = info => {
        console.log(info)
        if (info.file.status === 'uploading') {
            this.setState({ file: info.file });
        }
        if (info.file.status === 'done') {
            this.setState({ fileList: [] });
            message.success(`${info.file.name} file uploaded successfully`);
            this.props.dispatch(actions.loadAssets());
            return;
        }
        if(info.file.status === 'error'){
            this.setState({ file: null, loading:false, list:[]});
            message.error("An error has occured while uploading the file.");
        }

        this.setState({ fileList: [...info.fileList] });
    };


    onRemove = info => {
        this.setState({ file: null, loading:false, fileList:[]});

    };

    onCancelModal = () => {
        this.setState({modal:null})
    }

    render() {
        console.log('render filemanager', this.props.assets)
        return (
            <div>
                 <Dragger name='file'
                    style = {{marginBottom:'10px'}}
                    action={EP.UPLOAD_IMAGE()}
                    headers={{Authorization:`JWT ${this.props.session.token}`}}
                    //data={{app:this.props.appId}}
                    beforeUpload={this.beforeUpload}
                    onChange={this.onChange}
                    onRemove={this.onRemove}
                    fileList ={this.state.fileList}
                    >

                    <UploadOutlined />
                    {" Click or drop file to upload"}
                </Dragger>
                <Table
                    size = 'small'
                    pagination={false}
                    columns={this.columns}
                    dataSource={this.getData()}
                    />
                {this.state.modal && <Modal
                    title={extractName(this.state.modal.file)}
                    visible = {true}
                    footer={null}
                    onCancel={this.onCancelModal}
                    >
                    <img alt="example" style={{ width: '100%', maxHight:'400px' }} src={this.state.modal.file} />
                </Modal>}
            </div>
        );
    }
}


export default connect(state => {
    return { apps: state.apps, assets:state.assets, session:state.session};
  })(FileManager);
