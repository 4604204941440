import axios from 'axios';
import * as EP from '../../data/paths'
import * as actionTypes from './actionTypes'
import { message } from 'antd';


export const loadAssets = () => {
    return dispatch => {
        axios.get(EP.LOAD_ASSETS())
        .then(res => {
            dispatch(setAssets(res.data))
        })
        .catch(error => {
            console.log(error);
            message.error(error.message)
        })
    }
}

export const deleteAsset = (id) => {
    return dispatch => {
        axios.delete(`${EP.DELETE_ASSET()}/${id}`)
        .then(res => {
            dispatch(deleteAssetSuccess(id))
        })
        .catch(error => {
            console.log(error);
            message.error(error.message)
        })
    }
}






// private actions

const setAssets = (assets) => {
    return {
        type: actionTypes.SET_ASSETS,
        payload:{assets}
    }
}


const deleteAssetSuccess = (id) => {
    return {
        type: actionTypes.REMOVE_ASSET,
        payload: {id}
    }
}
