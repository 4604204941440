import React, { useContext, useState, useEffect, useRef } from 'react';
import { Table, Button, Popconfirm, Form, Input } from 'antd';
import {MenuOutlined} from '@ant-design/icons';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import _ from 'lodash'


const type = 'DragableBodyRow';
const EditableContext = React.createContext();

const DragableBodyRow = ({ index, moveRow, className, style, ...restProps }) => {
  const [form] = Form.useForm();
  const ref = React.useRef();
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: monitor => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
      };
    },
    drop: item => {
      moveRow(item.index, index);
    },
  });
  const [, drag, preview] = useDrag({
    item: { type, index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));

  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr
          ref={ref}
          className={`${className}${isOver ? dropClassName : ''}`}
          style={{ cursor: 'row-resize', ...style }}
          {...restProps}
        />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef();
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);



///////////////////////
///////////////////////

const toggleEdit = () => {
  setEditing(!editing);
  form.setFieldsValue({
    [dataIndex]: record[dataIndex],
  });
};

const save = async e => {
  try {
    const values = await form.validateFields();
    toggleEdit();
    handleSave({ ...record, ...values });
  } catch (errInfo) {
    console.log('Save failed:', errInfo);
  }
};

let childNode = children;

if (editable) {
  childNode = editing ? (
    <Form.Item
      style={{
        margin: 0,
      }}
      name={dataIndex}
      rules={[
        {
          required: true,
          message: `${title} is required.`,
        },
      ]}
    >
      <Input ref={inputRef} onPressEnter={save} onBlur={save} />
    </Form.Item>
  ) : (
    <div
      className="editable-cell-value-wrap"
      style={{
        paddingRight: 24,
      }}
      onClick={toggleEdit}
    >
      {children}
    </div>
  );
}

return <td {...restProps}>{childNode}</td>;
};

///////////////////////
///////////////////////


class ListInput extends React.Component {

  constructor(props) {
    super(props);

    console.log('ListInput', props)

    this.state = {
      data: props.value
    };

    this.columns = [
      {
        title:'draghandle',
        width: '30px',
        render: () => 
          <MenuOutlined style = {{ color: '#ddd', float:'left', fontSize: '20px'}}/>
        
      }]
    
    const size = Object.keys(props.schema).length;

    for(let each in props.schema){
      this.columns.push({
        title:props.schema[each].title,
        dataIndex:each,
        editable:true,
        width:`${Math.round(100/size)}%`
      })
    }
    
    this.columns.push(
      {
        title: 'operation',
        dataIndex: 'operation',
        width:'50px',
        render: (text, record) =>
          this.state.data.length >= 1 ? (
            <Popconfirm title="Sure to delete?" onConfirm={() => this.onDelete(record)}>
              <a>Delete</a>
            </Popconfirm>
          ) : null,
      })
  }

  components = {
    body: {
      row: DragableBodyRow,
      cell: EditableCell
    },
  };

  componentDidUpdate(prevProps, prevState){

      if(!_.isEqual(this.state.data, prevState.data)){
        console.log('   save changes in stagedApp')
        this.props.onChange(this.state.data)
      }
      else if(!_.isEqual(this.props.value, prevProps.value)){
        this.setState({data:this.props.value})
      }


      
  }

  getData = () => {
    const data = _.cloneDeep(this.state.data);
    data.map((item, i) => {item.key = String(i+1)})
    return data;
  }

  onMoveRow = (dragIndex, hoverIndex) => {
    const { data } = this.state;
    const dragRow = data[dragIndex];

    this.setState(
      update(this.state, {
        data: {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow],
          ],
        },
      }),
    );
  };

  onAddRow = () => {
    const { data } = this.state;
    const newData = {};
    for(let each in this.props.schema){
        newData[each] = `Enter ${this.props.schema[each].title}`
    }
    this.setState({
      data: [newData, ...data]
    });
  }

  onDelete = ({key}) => {
    const newData = [...this.state.data];
    const index = this.getData().findIndex(item => key === item.key);
    newData.splice(index, 1);
    this.setState({
      data: newData,
    });
  }

  onEdit = (row) => {
    const newData = [...this.state.data];
    const index = this.getData().findIndex(item => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, { ...item, ...row });
    this.setState({
      data: newData,
    });
  };


  render() {

    const columns = this.columns.map(col => {
      if (!col.editable) {
        return col;
      }

      return {
        ...col,
        onCell: record => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: this.onEdit,
        }),
      };
    });

    ////////////////////
    ////////////////////


    return (
      <div style = {{marginTop:-30}}> 
      <Button 
          onClick={this.onAddRow} 
          type="primary" 
          style={{ marginBottom: 16, float:'right', zIndex:999 }}>
          Add a row
        </Button>
     
        
        <DndProvider backend={HTML5Backend}>
          <Table
            
            size='small'
            rowClassName={() => 'editable-row'}
            columns={columns}
            dataSource={this.getData()}
            components={this.components}
            pagination={false}
            showHeader={false}
            onRow={(record, index) => ({
              index,
              moveRow: this.onMoveRow,
            })}
          />
        </DndProvider>
      </div>
      
    );
  }
}

export default ListInput