import React, { Component } from 'react';
import {Link} from 'react-router-dom'
import {Checkbox} from 'antd';
import * as actionApps from '../store/actions/apps'
import _ from 'lodash'




class ScreenTargetInput extends Component {

    constructor (props){
        super(props)

        // find group that current app belong to
        const appGroup = props.apps.find((group => (group.apps.find((app) => (app.id === props.app.id)))))
        const buildings = appGroup.subgroups;

        const screens = [];

        buildings.map(building => {
            building.screens.map(screen => {
                screens.push({slug:`${building.name}/${screen.name}`, id:screen.id, building, groupID:appGroup.id})
            })
        })

        this.state = {buildings, appGroup}
    }

    getCurrentScreens = () => {
        const appGroup = this.props.apps.find((group => (group.apps.find((app) => (app.id === this.props.app.id)))))
        const buildings = appGroup.subgroups;

        const screens = [];
        const screenIDs = [];

        buildings.map(building => {
            building.screens.map(screen => {
                screen.apps_list.map(app => {
                    if(app.app === this.props.app.id){
                        screens.push(screen);
                        screenIDs.push(screen.id)
                    }
                })
            })
        })
        return screenIDs;
    }

    getAppsList = (screenID) => {
        const appGroup = this.props.apps.find((group => (group.apps.find((app) => (app.id === this.props.app.id)))))
        const buildings = appGroup.subgroups;

        let appsList;

        buildings.map(building => {
            building.screens.map(screen => {
                if(screen.id === screenID){
                    appsList = screen.apps_list
                }
            })
        })

        return appsList
    }

    onCheck = (screenID, isChecked) => {
        let apps_list = _.cloneDeep(this.getAppsList(screenID));
        if(isChecked){
           apps_list = apps_list.filter(obj => (obj.app !== this.props.app.id))
        }else{
            apps_list.push({order:apps_list.length, app:this.props.app.id})
        }
        this.props.dispatch(actionApps.updateAppsList(screenID, apps_list));
    }

    render() {
        const currentScreens = this.getCurrentScreens();

          return (
              <div>
                  {this.state.buildings.map(building => 
                    <div key = {building.name + building.id} style = {{paddingBottom:'16px'}}>
                        <h3 style = {{marginBottom:'8px'}} >{building.name}:</h3>
                        {
                            building.screens.map(screen => 
                                <p key = {screen.name+screen.id} style = {{marginLeft: '20px'}}><Checkbox onClick = {() => this.onCheck(screen.id, currentScreens.includes(screen.id))} checked = {currentScreens.includes(screen.id)} style = {{marginRight: '10px'}}/><Link to={`/screens/${this.state.appGroup.name}/${building.name}/${screen.name}`}>{screen.name}</Link></p>
                            )
                        }
                    </div>
                    
                  )}
              </div>
        );
    }
}



export default ScreenTargetInput;