import _ from 'lodash'
import * as actionType from '../actions/actionTypes'


export function screensReducer (state = [], {type, payload}) {
    let result = state;
    return result
}

export function templatesReducer (state = [], {type, payload}) {
    let result = state;

    switch (type){
        case 'setTemplates':
            const {templates} = payload
            result = templates
            break

    }

    return result
}

export function assetsReducer (state = [], {type, payload}) {
    let result = state;

    switch (type){
        case actionType.SET_ASSETS:
            const {assets} = payload
            result = assets
            break
        case actionType.REMOVE_ASSET:
            const {id} = payload
            const stateClone = _.cloneDeep(state);
            const index = stateClone.findIndex(item => (item.id === id))
            stateClone.splice(index, 1)
            result = stateClone;
            break;

    }

    return result
}
