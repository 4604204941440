import React, { Component } from 'react';
import {UnorderedListOutlined, SaveOutlined, PictureOutlined, UndoOutlined, CopyOutlined} from '@ant-design/icons';
import {Button, Modal, Checkbox, message} from 'antd';
import '../index.css';
import ContentObjectItem from '../components/ContentObjectItem';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import styled from '@emotion/styled'
import _ from 'lodash'
import * as actionApps from '../store/actions/apps'
import { CLIENT_URL } from '../data/paths'



const ListElement = styled.div({
    marginBottom: '5px',
    '&.dragging-helper-class':{
        boxShadow: '0 0px 8px 0 rgba(0, 0, 0, 0.25)'
    }
})

const SortableItem = SortableElement(({app, templates, onItemClick}) => {
    return (
        <ListElement onClick={() => onItemClick(app)}>
            <ContentObjectItem key = {app.id} app = {app} templates = {templates}  dragable/>
        </ListElement>
    );
});

const SortableList = SortableContainer(({items, templates, onItemClick}) => {

  return (
    <div>
      {items.map((app, index) => (
        <SortableItem key={`item-${index}`} index={index} app={app} templates = {templates} onItemClick={onItemClick} />
      ))}
    </div>
  );
});


class ContentObjectSortableList extends Component {


    constructor(props){
        super(props)
        this.state = {
            screen:props.screen,
            stagedScreen:_.cloneDeep(props.screen)
        }
    }

    componentDidUpdate(prevProps) {
        if(!_.isEqual(prevProps.screen, this.props.screen)){
            this.setState({screen:this.props.screen, stagedScreen:_.cloneDeep(this.props.screen)})
            message.success('Changes saved');
        }
    }

    getAppByID = (appID) => {
        const {apps} = this.props;
        let currentGroup;
        let currentApp;
        for(let a = 0; a<apps.length; a++){
            currentGroup = apps[a]
            for(let b = 0; b<currentGroup.apps.length; b++){
                currentApp = currentGroup.apps[b]
                if(String(currentApp.id) === String(appID)){
                    return currentApp
                }
            }
        }

        return null
    }

    getAppsList = (screen) => {
        const list = []
        let apps;
        let app;

        this.props.templates.forEach(template => {
            apps = []
            screen.apps_list.forEach(item => {
                app = _.cloneDeep(this.getAppByID(item.app))
                if(app.template === template.id){
                    app.order = item.order
                    apps.push(app)
                }
            });

            apps.sort((a,b) => {
                if(a.order > b.order) return 1;
                else if(a.order < b.order) return -1;
                else return 0;
            })

            list.push({name:template.name, id:template.id, apps})
        });
        return list;
    }

    getMatchingApps = (templateID) => {
        const appsGroup = this.props.apps.find(group => (group.name === this.props.route.match.params.groupName))
        const apps = appsGroup.apps.filter(app => (app.template === templateID))
        return apps
    }

    getScreenName = () => {
        return this.props.route.match.params.screenID
    }

    isAppAssigned = (appID, section) => {
        return (section.apps.find(app => (app.id === appID)) !== undefined)
    }

    isStageOutOfSync = () => {
        return !_.isEqual(this.state.screen, this.state.stagedScreen)
    }

    hasListChanged = () => {
        return !_.isEqual(this.state.modal.selections, this.state.modal.defaultSelections)
    }

    onSortEnd = ({newIndex, oldIndex}, index, section) => {
        if(newIndex !== oldIndex){
            const stagedScreen = _.cloneDeep(this.state.stagedScreen)
            const subList = this.getAppsList(stagedScreen)[index].apps;
            console.log(stagedScreen, subList)
            const apps_list = stagedScreen.apps_list
            let localApp;
            arrayMove.mutate(subList, oldIndex, newIndex)
            subList.map((item, i) => {
                localApp = apps_list.find(app => (app.app === item.id));
                if(localApp){
                    localApp.order = i;
                }
            });

            this.setState({stagedScreen})

        }

    };

    onPublishButton = () => {
        this.props.dispatch(actionApps.updateAppsList(this.state.screen.id, this.state.stagedScreen.apps_list))
    };

    onCopyLinkButton = ({id}) => {
        const url = `${CLIENT_URL}/screen-${this.state.screen.id}/template-${id}`;

        try{
            const el = document.createElement('textarea');
            el.value = url;
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
            message.success("Link copied to clipboard.");
        }catch(err){
            console.log(err)
            message.error("Copy failed. Copy path from preview.");
        }
    };

    onPreviewButton = ({id}) => {
        const url = `${CLIENT_URL}/screen-${this.state.screen.id}/template-${id}`;
        window.open(url, "_blank")
    };

    onRestoreButton = () => {
        const originalScreen = this.state.screen;
        const stagedScreen = _.cloneDeep(originalScreen);
        this.setState({stagedScreen})
        message.success('All changes has been discarded.');
    };

    onManageButton = (section) => {
        const selections = []
        const matchingApps = this.getMatchingApps(section.id);
        const appIDs = []
        matchingApps.map(app => {selections.push(this.isAppAssigned(app.id, section)); appIDs.push(app.id)})
        this.setState({modal:{appIDs, section, selections, defaultSelections:_.cloneDeep(selections)}})
    };

    onOkModal = () => {
        const stagedScreen = _.cloneDeep(this.state.stagedScreen)
        const apps_list = stagedScreen.apps_list
        const {appIDs, selections} = this.state.modal;

        appIDs.map((id, i) => {
            if(selections[i]){
                if(!apps_list.find(app => (app.app === id))){
                    apps_list.push({order:appIDs.length, app:id})
                }
            }else{
                const itemIndex = apps_list.findIndex(app => (app.app === id));
                if(itemIndex > -1){
                    apps_list.splice(itemIndex, 1)
                }
            }
        })
        this.setState({modal:null, stagedScreen})
    };

    onCancelModal = (e) => {
        this.setState({modal:null})
    };

    onModalCheck = ({target}) => {
        const modal = _.cloneDeep(this.state.modal)
        modal.selections[target.i] = target.checked;
        this.setState({modal})
    }

    onItemClick = ({id}) =>{
        const {route} = this.props;
        route.history.push(`${route.location.pathname}/${id}/Content editor`)
    }

    render() {
        return (
            <div>
                <div style = {{paddingBottom: '20px'}}>
                    <div style = {{paddingBottom: '10px', display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
                        Drag and drop apps to change order
                        <Button.Group>
                            <Button disabled = {!this.isStageOutOfSync()} onClick = {this.onRestoreButton}>
                                <UndoOutlined/> Restore
                            </Button>
                            <Button disabled = {!this.isStageOutOfSync()} onClick = {this.onPublishButton}>
                                <SaveOutlined/> Save
                            </Button>
                        </Button.Group>
                    </div>
                </div>
                {this.getAppsList(this.state.stagedScreen).map((section, a) =>
                    <div key = {section.name} style = {{paddingBottom: '20px'}}>
                        <div style = {{paddingBottom: '10px', display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
                            <h3>
                            {`${section.name}`}
                            </h3>
                            <span> {this.renderControlls(section)}</span>
                        </div>
                        <SortableList
                            items = {section.apps}
                            templates = {this.props.templates}
                            onSortEnd={(data) => this.onSortEnd(data, a, section)}
                            onItemClick = {this.onItemClick}
                            helperClass="dragging-helper-class"
                            useDragHandle
                    />
                    </div>
                )}
                    {this.state.modal &&
                    <Modal
                        title={`Select ${this.state.modal.section.name} apps to display on ${this.getScreenName()} screen`}
                        okText = "Ok"
                        okButtonProps={{ disabled: !this.hasListChanged()}}
                        visible={this.state.modal != null}
                        onOk={this.onOkModal}
                        onCancel={this.onCancelModal}
                        confirmLoading={this.state.modal.confirmLoading}
                        >
                        {
                            this.getMatchingApps(this.state.modal.section.id).map((app, i) =>
                                <p key = {app.name+app.id} style = {{marginLeft: '20px'}}><Checkbox i = {i} onChange = {this.onModalCheck} defaultChecked = {this.state.modal.selections[i]} style = {{marginRight: '10px'}}>{app.name}</Checkbox></p>)
                        }
                    </Modal>
                }
            </div>
        );
    }

    renderControlls = (section) => {
        return(
            <Button.Group>
                <Button onClick = {() => this.onManageButton(section)}>
                <UnorderedListOutlined />Manage apps
                </Button>
                <Button onClick = {() => this.onCopyLinkButton(section)}>
                    <CopyOutlined/> Copy link
                </Button>
                <Button onClick = {() => this.onPreviewButton(section)}>
                    <PictureOutlined/> Preview
                </Button>
            </Button.Group>
        )
    }
}

export default ContentObjectSortableList;
