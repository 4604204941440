import React, { Component } from 'react';
import {PlusOutlined} from '@ant-design/icons';
import {Button, Modal, Input} from 'antd';
import '../index.css';
import ContentObjectItem from '../components/ContentObjectItem';
import * as actionApps from '../store/actions/apps'



class ContentObjectList extends Component {

    state = {modal:null};

    onEnterApp = (id) => {
        const {route} = this.props;
        route.history.push(`${route.location.pathname}/${id}/Content editor`)
    }

    onAddApp = (templateName, groupName) => {
        this.setState({modal:{templateName, groupName, appName:''}})
    }

    onCancelModal = (e) => {
        this.setState({modal:null})
    }

    onAddAppCallback = () => {
        this.setState({modal:null})
    }

    onOkModal = (e) => {
        const templateID = this.props.templates.find(template => (template.name === this.state.modal.templateName)).id
        const groupID = this.props.list.find(group => (group.name === this.state.modal.groupName)).id
        const {appName} = this.state.modal
        
        this.props.dispatch(actionApps.createApp(groupID, templateID, appName, this.onAddAppCallback))

        const modal = {...this.state.modal, confirmLoading:true}
        this.setState({modal})
    }

    onNameChange = (e) => {
        const modal = {...this.state.modal, appName:e.target.value}
        this.setState({modal})
    }

    isNameValid = () => {
        const {appName} = this.state.modal
        if(!appName)
            return false;
        return true;
    }

    render() {
        const {templateName} = this.props.route.match.params;
        return (
            <div>
                {this.props.list.map((section, a) => 
                    <div key = {section.name} style = {{paddingBottom: '20px'}}>
                        <div style = {{paddingBottom: '10px', display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
                            <h3>{section.name}</h3>
                            <span>
                                <Button onClick={() => this.onAddApp(templateName, section.name)}><PlusOutlined />Add new</Button>
                            </span>
                        </div>
                        {section.apps.map((app, a) => 
                            <div key = {app.id} onClick={() => this.onEnterApp(app.id)} id = {app.id} style = {{marginBottom:'5px'}}>
                                <ContentObjectItem  app = {app} templates = {this.props.templates} clickable />
                            </div>
                        )}
                    </div>
                )}{this.state.modal && 
                    <Modal
                        title={`New ${this.state.modal.templateName} app in ${this.state.modal.groupName}`}
                        okText = "Create"
                        okButtonProps={{ disabled: !this.isNameValid()}}
                        visible={this.state.modal != null}
                        onOk={this.onOkModal}
                        onCancel={this.onCancelModal}
                        confirmLoading={this.state.modal.confirmLoading}
                        >
                        <Input disabled = {this.state.modal.confirmLoading} allowClear onChange = {this.onNameChange} placeholder="Enter unique name" />
                    </Modal>
                }
                
            </div>
        );
    }
}

export default ContentObjectList;
