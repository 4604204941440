import axios from 'axios';
import * as EP from '../../data/paths'
import * as actionTypes from './actionTypes'
import { message } from 'antd';


export const loadApps = () => {
    return dispatch => {
        axios.get(EP.LOAD_GROUPS())
        .then(res => {
            dispatch(setApps(res.data))
        })
        .catch(error => {
            console.log(error);
            message.error(error.message)
        })
    }
}

export const createApp = (group, template, name, callback) => {
    return dispatch => {
        axios.post(EP.ADD_APP(), {group, template, name, data:{}, occurrences:[]})
        .then(res => {
            callback()
            dispatch(addApp(res.data, group))
        })
        .catch(error => {
            callback()
            console.log(error);
            message.error(error.message)
        })
    }
}

export const updateApp = (appID, data, occurrences, successCallback) => {
    console.log('updateApp, occurrences', occurrences )
    return dispatch => {
        axios.patch(`${EP.PATCH_APP()}/${appID}`, {data, occurrences})
        .then(res => {
            console.log(res)
            dispatch(updateAppSuccess(appID, res.data))
            successCallback();
        })
        .catch(error => {
            console.log(error);
            message.error(error.message)
        })
    }
}

export const deleteApp = (appID, successCallback) => {
    return dispatch => {
        axios.delete(`${EP.DELETE_APP()}/${appID}`)
        .then(res => {
            successCallback();
            dispatch(deleteAppSuccess(appID))
        })
        .catch(error => {
            console.log(error);
            message.error(error.message)
        })
    }
}

export const updateAppsList = (screenID, apps_list) => {
    console.log('param', apps_list)
    return dispatch => {
        axios.patch(`${EP.UPDATE_SCREEN()}/${screenID}`,{apps_list})
        .then(res => {
            console.log('response', res)
            //dispatch(updateAppsListSuccess(screenID, res.data.apps_list))
            dispatch(updateAppsListSuccess(screenID, apps_list))
        })
        .catch(error => {
            console.log(error);
            message.error(error.message)
        })
    }
}





// private actions

const setApps = (apps) => {
    return {
        type: actionTypes.SET_APPS,
        apps
    }
}

const addApp = (app, groupID) => {
    return {
        type: actionTypes.ADD_APP,
        app,
        groupID
    }
}

const updateAppSuccess = (appID, data) => {
    return {
        type: actionTypes.UPDATE_APP,
        appID,
        data
    }
}

const updateAppsListSuccess = (screenID, apps_list) => {
    return {
        type: actionTypes.UPDATE_APPS_LIST,
        screenID,
        apps_list
    }
}

const deleteAppSuccess = (appID) => {
    return {
        type: actionTypes.DELETE_APP,
        appID
    }
}
