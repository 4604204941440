import React, { Component } from 'react';
import {Button, message, Tabs, Popconfirm, Select} from 'antd';
import { PictureOutlined, UndoOutlined, PlaySquareOutlined, ScheduleOutlined, EditOutlined, SaveOutlined, DeleteOutlined} from '@ant-design/icons';
import GenericInput from '../components/GenericInput'
import _ from 'lodash'
import * as actionApps from '../store/actions/apps'
import ScreenTargetInput from '../components/ScreenTargetInput';
import SchedulerInput from '../components/SchedulerInput';
import { StickyContainer, Sticky } from 'react-sticky';
import { CLIENT_URL } from "../data/paths";


const { TabPane } = Tabs;

const renderTabBar = (props, DefaultTabBar) => (
    <Sticky bottomOffset={0}>
      {({ style }) => (
        <DefaultTabBar {...props} className="site-custom-tab-bar" style={{ ...style, zIndex:999, background:'white' }} />
      )}
    </Sticky>
  );



class ContentObjectEditor extends Component {

    constructor(props) {
        super(props);

        console.log('ContentObjectEditor', props)

        this.state = {
            stageApp:props.app
        };
    }

    componentDidUpdate(prevProps) {
        if(!_.isEqual(prevProps.app, this.props.app)){
            this.setState({stageApp:this.props.app})
        }
    }

    goToList = (e) => {
        const {route} = this.props;
        let path = route.location.pathname;
        path = path.split('/', 3)
        path = path.join('/')
        route.history.push(path)
    }

    getTemplateByID = (id) => {
        return this.props.templates.find(template => (template.id === id))
    }

    getCurrentTab = () => {
        const tab = this.props.route.match.params.tab
        if(tab)
            return tab;

        return 'Content editor'

    }

    setPropertyValue = (propertyName, value) => {
        const stageApp = _.cloneDeep(this.state.stageApp)
        stageApp.data[propertyName] = value;
        this.setState({stageApp})
    }

    setOccurences = (range) => {
        const stageApp = _.cloneDeep(this.state.stageApp)
        stageApp.occurrences = (range !== null) ? [range] : [];
        this.setState({stageApp})
    }

    isStageOutOfSync = () =>{
        if(!this.state) return false;
        const {stageApp} = this.state;
        if(!stageApp) return false;
        const originalApp = this.props.app;
        return !_.isEqual(stageApp, originalApp)
    }

    onTab = (tabName) => {
        const {route} = this.props;
        let path = route.location.pathname;
        //path = path.split('/', 4)
        path = path.split('/')
        path.splice(path.length-1, 1);
        path.push(tabName)
        path = path.join('/')
        route.history.push(path)
    }

    onPublishButton = () => {
        const {stageApp} = this.state;
        const {dispatch} = this.props;

        dispatch(actionApps.updateApp(stageApp.id, stageApp.data, stageApp.occurrences, () => {
            message.success('Publish success.');
        }));
    };

    onPreviewButton = () => {
        //const url = `${this.getTemplateByID(this.state.stageApp.template).preview_url}?spid=${this.state.stageApp.id}`;
        const url = `${CLIENT_URL}/preview-${this.state.stageApp.id}`;
        window.open(url, "_blank")
    };

    onRestoreButton = () => {
        const originalApp = this.props.app;
        const stageApp = _.cloneDeep(originalApp);
        this.setState({stageApp})
        message.success('All changes has been discarded.');
        console.log('state restored', stageApp)
    };

    onConfirmDelete = () => {
        const {stageApp} = this.state;
        const {dispatch} = this.props;

        dispatch(actionApps.deleteApp(stageApp.id, () => {
            message.success(`App ${stageApp.name} has been deleted.`)
            this.goToList(null)
        }));
    }

    render() {

        return(
            <div>
                <StickyContainer>
                    <Tabs size = 'small' renderTabBar={renderTabBar} onTabClick = {this.onTab} activeKey={this.getCurrentTab()} tabBarExtraContent = {this.renderControlls()}>
                        <TabPane tab={<span><EditOutlined />Content editor</span>} key="Content editor">
                            {this.renderTemplateFields(this.state.stageApp)}
                        </TabPane>
                        <TabPane tab={<span><ScheduleOutlined />Scheduler</span>} key="Scheduler">
                            <SchedulerInput app = {this.props.app} stagedApp = {this.state.stageApp} setOccurences = {this.setOccurences}/>
                        </TabPane>
                        <TabPane tab={<span><PlaySquareOutlined />Screen targeting</span>} key="Screen targeting">
                            <ScreenTargetInput dispatch = {this.props.dispatch} app = {this.props.app} apps = {this.props.apps}/>
                        </TabPane>

                    </Tabs>
                </StickyContainer>
            </div>
        )
    }


    renderControlls = () => {
        return(
            <Button.Group>
                <Button disabled = {!this.isStageOutOfSync()} onClick = {this.onRestoreButton}>
                    <UndoOutlined/> Restore
                </Button>
                <Popconfirm
                    placement="bottom"
                    title="Are you sure to delete this app?"
                    onConfirm={this.onConfirmDelete}
                    okText="Yes"
                    cancelText="No"
                >
                    <Button>
                        <DeleteOutlined/> Delete
                    </Button>
                </Popconfirm>
                <Button disabled = {this.isStageOutOfSync()} onClick = {this.onPreviewButton}>
                    <PictureOutlined/> Preview
                </Button>
                <Button disabled = {!this.isStageOutOfSync()} onClick = {this.onPublishButton}>
                    <SaveOutlined/> Publish
                </Button>
            </Button.Group>
        )
    }



    renderTemplateFields = (app) => {
        const template = this.getTemplateByID(app.template)
        const propertiesObj = template.schema.properties
        const requiredFields = template.schema.required
        const widgetTypes = template.ui_schema

        let propertiesArr = [];
        let prop
        for(let propName in propertiesObj){
            prop = {...propertiesObj[propName]};
            prop.isRequired = (requiredFields.indexOf(propName) > -1)
            prop.name = propName
            prop.appId = app.id
            prop.widget = widgetTypes[propName]['ui:widget']
            propertiesArr.push(prop)
        }

        //console.log(propertiesArr)
        return (
            <div key = {app.id}>
                {propertiesArr.map((param, i) =>
                    <GenericInput
                        key = {i}
                        index = {i}
                        definition = {param}
                        properties = {app.data}
                        groupProperties = {{}} // {this.props.groups[selectedAppPath[0]].data}
                        update={this.setPropertyValue}>
                    </GenericInput>)}
            </div>
        )
    }


}

export default ContentObjectEditor;
